import React from "react";
import { connect } from "react-redux";
import { changeSection, changeTab } from "@actions/editmenu";
import {
  loadEvent,
  updateEvent,
  showModal,
  setSelectedPolicy,
  hideModal,
} from "@actions";
import EditMenu from "./EditMenu";
import { savePolicy } from "../../../../../actions";
const mapStateToProps = (state) => {
  const menu = state.editmenu;
  const sections = Object.assign({}, menu.sections);
  const exclude = menu.exclude;
  const excludeTabs = menu.excludeTabs;

  if (exclude.length > 0 || excludeTabs.length > 0) {
    Object.entries(sections).map(([section, value]) => {
      if (exclude.includes(section)) {
        delete sections[section];
      } else {
        if (excludeTabs.length > 0) {
          excludeTabs.map((et) => {
            if (value.items.hasOwnProperty(et)) {
              delete value.items[et];
            }
          });
        }
      }
    });
  }

  return {
    sections,
    activeSection: menu.activeSection,
    activeTab: menu.activeTab,
    tabItems: menu.sections[menu.activeSection].items,
    fetching: state.api.events.edit.fetching,
    policyUpdating: state.api.accesspolicies.list.fetching,
    updating: menu.updating,
    exclude: menu.exclude,
    ready: state.editmenu.ready,
    fakeChild: state.editmenu.fakeChild || "",
    selectedPolicy: state.api.accesspolicies.selectedPolicy,
    policies: state.api.accesspolicies.list.data.reduce(
      (prev, next) => ({
        ...prev,
        [next.id]: (
          <div
            className="event-policies-dropdown"
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
          >
            <div
              style={{
                fontSize: 16,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <span
                className={
                  next.type == "public"
                    ? "icon-public-policy"
                    : "icon-private-policy"
                }
              ></span>
              {!!next.saveUsersToCrm && (
                <span style={{ fontSize: 7 }}>CRM</span>
              )}
            </div>
            <span
              title={next.name + (next.default ? " (Private/Default)" : "")}
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {next.name + (next.default ? " (Private/Default)" : "")}
            </span>
          </div>
        ),
      }),
      {}
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePolicy: (eventId, request, policyId) =>
      dispatch(savePolicy(eventId, request, policyId)),
    setSelectedPolicy: (policyId) => dispatch(setSelectedPolicy(policyId)),
    changeSection: (key) => {
      dispatch(changeSection(key));
    },
    changeTab: (key) => {
      dispatch(changeTab(key));
    },
    loadEvent: (eventId) => {
      dispatch(loadEvent(eventId));
    },
    showModal: (content) => dispatch(showModal(content)),
    hideModal: () => dispatch(hideModal()),
    updateEvent: (eventId, event) => {
      return new Promise((resolve, reject) => {
        dispatch(updateEvent(eventId, event))
          .then((data) => resolve(data))
          .catch((err) => reject(err));
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMenu);
