import React from "react";
import SelectCheckboxes from "@layout/SelectCheckboxes";
import Select from "@layout/Select2";
import Search from "@layout/Search";
import PropTypes from "prop-types";
import BulkImportUsers from "../../../events/edit/sections/registration/AccessPolicies/partials/BulkImportUsers";
import { HeaderPagination } from "@layout/Pagination";
export default class UsersHeader extends React.Component {
  constructor(props) {
    super(props);
    const groups = {};
    this.props.policyGroups.data.map((group) => {
      groups[group.id] = group.name;
    });
    this.state = {
      groups,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.policyGroups.data) !==
      JSON.stringify(this.props.policyGroups.data)
    ) {
      const groups = {};
      nextProps.policyGroups.data.map((group) => {
        groups[group.id] = group.name;
      });
      this.setState({ groups });
    }
  }
  render() {
    const { selectedUsers, userSet, advancedSearchOptions, policy } =
      this.props;
    const columns = {};

    const policyFilters =
      (this.props.columns[policy.eventId] &&
        this.props.columns[policy.eventId][policy.id]) ||
      this.props.columns.default;
    Object.entries(policyFilters).map(([k, v]) => {
      if (v.hasOwnProperty("filter")) {
        v.checked = v.filter ? true : false;
        if (
          k === "password" ||
          k === "speaker" ||
          k === "actions" ||
          k === "createdAt"
        ) {
          return true;
        }
        columns[k] = v;
      }
    });
    const prompt = <span className="icon-columns"></span>;
    const options = {};
    options["ExportUsersAll"] = "Export to Excel (All Participants)";

    if (policy.type !== "public") {
      options["ImportUsers"] = "Import Participants";
    }

    const groupId = ((this.props.groupId || {})[this.props.eventId] || {})[
      this.props.policyId
    ];
    if (userSet || selectedUsers.length > 0 || groupId) {
      options["ExportUsers"] = "Export to Excel (Current Search)";
      if (selectedUsers.length > 0 || this.props.selectedAll) {
        options["PrintCert"] = "Bulk Print Certificates";
        options["PrintCard"] = "Bulk Print Cards";
      }
    }
    const { p, rpp, totalRows } = this.props.meta;
    return (
      <div className="users-header tab-header">
        <div className="left">
          <div className="users-buttons group-select">
            <Select
              selectedBackground="#f0f0f4"
              placeholder={[
                <div
                  key="placeholder-select-group"
                  className="select-button-placeholder"
                >
                  <span className="icon-more-options"></span>
                </div>,
              ]}
              isSearchable={false}
              className="more-actions select-button"
              isDropDownButton={true}
              isClearable={false}
              placeholderInsideSelect={false}
              options={options}
              onChange={(action) => {
                if (
                  [
                    "PrintCert",
                    "PrintCard",
                    "ExportUsers",
                    "ExportUsersAll",
                  ].includes(action)
                ) {
                  return this.props[`on${action}`](selectedUsers);
                }
                if (action === "AdvancedSearch") {
                  this.props.doAction("AdvancedSearch", false, {
                    policy: this.props.policy,
                  });
                }
                if (action === "ImportUsers") {
                  return this.props.fbulkImport(
                    <BulkImportUsers
                      policy={this.props.policy}
                      event={this.props.event}
                      hideModal={this.props.hideModal}
                      importUsers={this.props.importUsers}
                      addNotification={this.props.addNotification}
                    />
                  );
                }
                this.props.doAction(action, false, {
                  policy: this.props.policy,
                  printingSettings: this.props.printingSettings,
                });
              }}
            />
          </div>
          <div className="user-buttons">
            <SelectCheckboxes
              static={["firstName", "lastName", "id"]}
              reset={["firstName", "lastName", "id", "email", "subscription"]}
              className="filters"
              options={columns}
              onChange={(columns, newSort) =>
                this.props.changeFilters(
                  this.props.eventId,
                  this.props.policyId,
                  columns,
                  newSort
                )
              }
              fixedPrompt={true}
              sortable={true}
              placeholder={prompt}
              onSortEnd={(newSort) =>
                this.props.changeFilters(
                  this.props.eventId,
                  this.props.policyId,
                  columns,
                  newSort
                )
              }
            />
          </div>

          <div
            className={`users-buttons group-search${groupId ? " active" : ""}`}
            title={this.state.groups[groupId] || "Search by group"}
          >
            <Select
              selectedBackground="#f0f0f4"
              isDropDownButton={true}
              className={"select-button" + (groupId ? " active" : "")}
              options={this.state.groups}
              isClearable={false}
              hasIndicator={false}
              placeholderInsideSelect={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              placeholder={[
                <span
                  key="placeholder-select-group-icon"
                  className="icon-groups"
                ></span>,
              ]}
              onChange={(value) => {
                this.props.setGroupId(
                  this.props.eventId,
                  this.props.policyId,
                  value
                );
                return this.props.getParticipants({
                  eventId: this.props.eventId,
                  policyId: this.props.policyId,
                  query: { groupId: value, p: 1 },
                });
              }}
              value={groupId || null}
            />
            {groupId && (
              <span
                className="icon-close group-close"
                onClick={() => {
                  this.props.setGroupId(
                    this.props.eventId,
                    this.props.policyId,
                    ""
                  );
                  return this.props.getParticipants({
                    eventId: this.props.eventId,
                    policyId: this.props.policyId,
                    query: { groupId: "", p: 1 },
                  });
                }}
              ></span>
            )}
          </div>
          <div className="users-buttons">
            <div className="search-advanced-wrapper">
              <button
                style={{ position: "relative" }}
                className={`search-advanced btn tab-header-btn${
                  Object.keys(advancedSearchOptions).length > 0 ? " active" : ""
                }`}
                onClick={() =>
                  this.props.doAction("AdvancedSearch", false, {
                    policy: this.props.policy,
                  })
                }
              >
                <span className="icon-settings"></span>
                {Object.keys(advancedSearchOptions).length > 0 ? (
                  <span
                    className="icon-close"
                    title="Reset Advanced Search"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.resetAdvanced(
                        this.props.policy.id,
                        this.props.policy.eventId
                      );
                    }}
                  ></span>
                ) : null}
              </button>
            </div>
          </div>
          <div className="search-div">
            <Search
              className={this.state.searchString ? "active" : ""}
              reset={this.props.reset || false}
              onSearch={(queryString) => {
                this.setState({ searchString: queryString });
                this.props.getParticipants({
                  eventId: this.props.eventId,
                  policyId: this.props.policyId,
                  query: { search: queryString, p: 1 },
                });
              }}
              visible={true}
              timeout={400}
            />
          </div>
        </div>
        <div className="right">
          <HeaderPagination
            fetching={this.props.fetching}
            p={p}
            rpp={rpp}
            totalRows={totalRows}
            onChangePage={(data) =>
              this.props.getParticipants({
                eventId: this.props.eventId,
                policyId: this.props.policyId,
                query: data,
              })
            }
          />
          {this.props.policy.type !== "public" && (
            <button
              type="button"
              className="btn create rounded"
              onClick={() =>
                this.props.doAction("CreateUser", false, {
                  policy: this.props.policy,
                  createUser: this.props.createUser,
                })
              }
            >
              Create
            </button>
          )}
        </div>
      </div>
    );
  }
}

UsersHeader.propTypes = {
  meta: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  getParticipants: PropTypes.func.isRequired,
  changeFilters: PropTypes.func.isRequired,
  doAction: PropTypes.func.isRequired,
  policy: PropTypes.object.isRequired,
  printingSettings: PropTypes.object.isRequired,
  searchUsers: PropTypes.func.isRequired,
  resetAdvanced: PropTypes.func.isRequired,
  advancedSearch: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  advancedSearchOptions: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  selectedAll: PropTypes.bool.isRequired,
  userSet: PropTypes.bool.isRequired,
  createUser: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
  policyGroups: PropTypes.object,
  reset: PropTypes.bool,
  event: PropTypes.object,
  hideModal: PropTypes.func,
  importUsers: PropTypes.func,
  addNotification: PropTypes.func,
  fbulkImport: PropTypes.func,
  setGroupId: PropTypes.func,
  eventId: PropTypes.number,
  policyId: PropTypes.number,
  groupId: PropTypes.object,
};
