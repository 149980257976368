import { isEmpty, isNumeric } from "@helpers";
import moment from "moment";

const ApiHelper = {
  dateFormat: "DD-MM-Y HH:mm:ss",
  dateFormatNoTime: "DD-MM-YYYY",

  defaultCriteria: {
    p: 1,
    rpp: 20,
    orderBy: "id",
    order: "DESC",
    search: "",
    status: "",
    active: "",
    archived: 0,
    owner: "",
    dateRange: "",
  },

  defaultUserCriteria: {
    p: 1,
    rpp: 20,
    orderBy: "id",
    order: "DESC",
    search: false,
    minutes: true,
    points: true,
    currentRoom: true,
    info: true,
    hasHit: undefined,
    products: true,
    sponsorships: false,
    subscriptions: true,
    questionnaires: true,
    accomodation: true,
    abstracts: true,
    withAbstracts: true,
    policyIds: undefined,
    policyTypes: undefined,
    groupId: undefined,
  },

  hasActiveFilters: (filters) => {
    return ApiHelper.normalizeFilters(filters) !== "";
  },

  prepareUserSearch: (params) => {
    let query = "";
    const additional = {
      dateRange: {
        from: null,
        to: null,
      },
      minutesRange: {
        from: null,
        to: null,
      },
    };

    Object.entries(params).map(([k, v]) => {
      if (v !== "" && v !== null) {
        let key = null;
        if (k === "minutesRangeFrom" || k === "minutesRangeTo") {
          key = k.split("minutesRange")[1].toLowerCase();
          additional.minutesRange[key] = parseInt(v);
        } else if (k === "dateFrom" || k === "dateTo") {
          key = k.split("date")[1].toLowerCase();
          additional.dateRange[key] = moment(v, ApiHelper.dateFormat).format(
            ApiHelper.dateFormat
          );
        } else {
          query += `&${k}=${v}`;
        }
      }
    });

    Object.entries(additional).map(([k, v]) => {
      if (!isEmpty(v)) {
        if (v.from !== null || v.to !== null) {
          query += `&${k}=${JSON.stringify(v)}`;
        }
      }
    });

    return query;
  },

  normalizeUserCriteria: (criteria) => {
    const response = Object.assign({}, ApiHelper.defaultUserCriteria);

    Object.keys(criteria).map((key) => {
      if (response.hasOwnProperty(key)) {
        if (criteria[key] !== undefined) {
          response[key] = criteria[key];
        }
      }
    });
    return response;
  },

  normalizeApiMeta: (meta, count) => {
    if (!meta.hasOwnProperty("p")) meta.p = 1;

    if (!meta.hasOwnProperty("rpp")) meta.rpp = count;

    if (!meta.hasOwnProperty("totalRows")) meta.totalRows = count;

    if (!meta.hasOwnProperty("search")) meta.search = "";

    if (!meta.hasOwnProperty("archived")) meta.archived = 0;

    return meta;
  },

  normalizeCriteria: (criteria) => {
    const response = Object.assign({}, ApiHelper.defaultCriteria);

    Object.keys(criteria).map((key) => {
      if (response.hasOwnProperty(key)) {
        response[key] = criteria[key];
      }
    });

    return response;
  },

  normalizeFilters: (filters) => {
    let prepareFilters = "";
    Object.entries(filters).map(([key, val]) => {
      if (!isEmpty(val)) {
        switch (key) {
          case "dateRange": {
            if (val.from !== null || val.to !== null) {
              prepareFilters += `&${key}=${JSON.stringify(val)}`;
            }
            break;
          }
          case "active": {
            if (val.includes("active") && val.includes("inactive")) {
              val = val.filter((v) => !["active", "inactive"].includes(v));
            }

            for (let k of val) {
              let tvalue = true;
              if (k === "inactive") {
                k = "active";
                tvalue = false;
              }
              prepareFilters += `&${k}=${tvalue}`;
            }

            break;
          }
          default: {
            prepareFilters += `&${key}=${val.join(",")}`;
            break;
          }
        }
      }
    });

    return prepareFilters;
  },

  getNameByEndpoint: (endpoint, plural = false, action) => {
    const endpoints = {
      events: {
        name: "Event",
        plural: "Events",
      },
      accesspolicies: {
        name: "Access Policy",
        plural: "Access Policies",
      },
      users: {
        name: "User",
        plural: "Users",
      },
      rooms: {
        name: "Room",
        plural: "Rooms",
      },
      sessions: {
        name: "Session",
        plural: "Sessions",
      },
      sessionType: {
        name: "Session Type",
        plural: "Session Types",
      },
      bankAccount: {
        name: "Bank Account",
        plural: "Bank Accounts",
      },
      speeches: {
        name: "Speech",
        plural: "Speeches",
      },
      persons: {
        name: "Person",
        plural: "Persons",
      },
      roles: {
        name: "Role",
        plural: "Roles",
      },
      staff: {
        name: "Staff",
        plural: "Staff",
      },
      subscriptions: {
        name: "Subscription",
        plural: "Subscriptions",
      },
      subscriptionTypes: {
        name: "Subscription Type",
        plural: "Subscription Types",
      },
      subscriptionPeriods: {
        name: "Subscription Period",
        plural: "Subscription Periods",
      },
      hotels: {
        name: "Hotel",
        plural: "Hotels",
      },
      hotelRooms: {
        name: "Room",
        plural: "Rooms",
      },
      abstracts: {
        name: "Abstract",
        plural: "Abstracts",
      },
      topics: {
        name: "Topic",
        plural: "Topics",
      },
      rentables: {
        name: "Rentable",
        plural: "Rentables",
      },
    };

    if (endpoints.hasOwnProperty(endpoint)) {
      let object = endpoints[endpoint];
      if (endpoint === "subscriptions") {
        if (action === "createType") object = endpoints["subscriptionTypes"];
        else if (action === "createPeriod")
          object = endpoints["subscriptionPeriods"];
      } else if (endpoint === "hotels") {
        if (action === "createRoom") object = endpoints["hotelRooms"];
      } else if (endpoint === "sessions") {
        if (action === "createSessionType") object = endpoints["sessionType"];
      }
      return plural ? object.plural : object.name;
    }

    return endpoint;
  },

  getItemIdByMeta: (meta) => {
    const m = Object.assign({}, meta),
      url = m.url.replace(/\/$/, ""),
      id = url.split("/").pop();

    if (isNumeric(id)) {
      return id;
    }
    throw "Could not determine item id on delete action.";
  },

  checkMeta: (meta, prevMeta, cache = true) => {
    if (isEmpty(prevMeta)) {
      return false;
    }

    for (const [key, value] of Object.entries(meta)) {
      if (!prevMeta.hasOwnProperty(key)) {
        cache = false;
        break;
      }
      if (value !== prevMeta[key]) {
        cache = false;
        break;
      }
    }

    return cache;
  },
};

export default ApiHelper;
