import React from "react";
import Form from "@components/form";
import PropTypes from "prop-types";
import { isEmpty } from "@helpers";
import formFields from "@helpers/form-fields";
import { loadUsers } from "@actions/users";
import { hideModal } from "@actions";
import { connect } from "react-redux";
import { createData, updateData } from "@actions/products";
import SearchAndSelect from "@layout/SearchAndSelect";

const yesNoKeys = [];
class SingleData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: this.props.item
        ? `${this.props.item.eventUserId} - ${this.props.item.eventUserFullName}`
        : "",
      focused: "",
      userId: this.props.item ? this.props.item.eventUserId : null,
      updating: false,
      queryString: "",
    };
    this.timeout = null;
    this.onSelectChange = this.onSelectChange.bind(this);
  }
  onSelectChange(userId = null, userName = "") {
    this.setState({ userId, userName });
  }
  render() {
    const options = {};
    if (this.props.prices.length > 0) {
      this.props.prices.map((price) => {
        options[price.id] = price.name;
      });
    }
    formFields.forms.products.data.eventProductPriceId.options = options;

    return (
      <React.Fragment>
        {this.props.h2 === "Edit" ? (
          <h2>Edit data</h2>
        ) : (
          <h2>Create new data</h2>
        )}
        <br />
        <div className="new-staff form-container">
          {this.props.targetUser === 1 && (
            <div className="form-group">
              <label>Search and select the user from the list*</label>
              <SearchAndSelect
                onSelectChange={this.onSelectChange}
                selectValue={this.state.userName}
                onSearch={(query) =>
                  this.props.searchUsers(
                    query,
                    null,
                    this.props.eventId,
                    this.props.policyIds
                  )
                }
                placeholder={this.state.userName || "Search participant..."}
              />
            </div>
          )}
          <Form
            componentName={"products"}
            formName="data"
            value={this.props.h2 === "Edit" ? this.props.item : {}}
            onSubmit={(data) => {
              if (isEmpty(data.errors)) {
                Object.entries(data.value).map(([k, v]) => {
                  if (yesNoKeys.indexOf(k) !== -1) {
                    if (v === "yes") {
                      data.value[k] = true;
                    } else if (v === "no") {
                      data.value[k] = false;
                    }
                  }
                  if (data.value[k] === "") {
                    delete data.value[k];
                  }
                });
                if (this.props.h2 === "Edit") {
                  this.props.updateData(
                    this.props.eventId,
                    this.props.productId,
                    this.props.item.id,
                    data.value,
                    this.state.userId
                  );
                } else {
                  this.props.createData(
                    this.props.eventId,
                    this.props.productId,
                    data.value,
                    this.state.userId
                  );
                }
              }
            }}
            onCancel={this.props.hideModal}
          />
        </div>
      </React.Fragment>
    );
  }
}

SingleData.propTypes = {
  eventId: PropTypes.number,
  h2: PropTypes.string,
  hideModal: PropTypes.func,
  productId: PropTypes.number,
  listGroups: PropTypes.func,
  policyGroups: PropTypes.array,
  createPrice: PropTypes.func,
  targetUser: PropTypes.number,
  allPolicyUsers: PropTypes.array,
  eventUserFullName: PropTypes.string,
  eventUserId: PropTypes.number,
  prices: PropTypes.array,
  searchUsers: PropTypes.func,
  createData: PropTypes.func,
  item: PropTypes.object,
  updateData: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  policyIds: PropTypes.array,
};

SingleData.defaultProps = {
  value: {},
};
const mapStateToProps = (state) => {
  return {
    allPolicyUsers: state.users.allPolicyUsers.data,
    fetching: state.users.fetching,
    policyId: state.page.params.pid,
    policyGroups: state.api.policyGroups.list.data,
    prices: state.api.products.listPrices.data,
    policyIds: state.api.accesspolicies.list.data
      .filter((p) => p.type !== "public")
      .map((p) => p.id),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchUsers: (queryString, policyId, eventId, policyIds) => {
      return dispatch(
        loadUsers(
          eventId,
          policyId,
          {
            search: queryString,
            policyIds: policyIds.join(","),
            points: false,
            minutes: false,
            currentRoom: false,
            subscriptions: false,
            accomodation: false,
            products: false,
            abstracts: false,
          },
          null,
          null,
          null,
          true,
          true
        )
      );
    },
    hideModal: () => {
      dispatch(hideModal());
    },
    createData: (eventId, productId, data, userId) => {
      dispatch(createData(eventId, productId, data, userId));
    },
    updateData: (eventId, productId, dataId, data, userId) => {
      dispatch(updateData(eventId, productId, dataId, data, userId));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleData);
