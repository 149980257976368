import React from "react";
import Table from "@layout/Table";
import { rootLevelObject } from "@helpers";
import Confirmer from "@layout/Confirmer";
import Tooltip from "@material-ui/core/Tooltip";
import Confirm from "@layout/Confirm";
import UsersHeader from "./partials/UsersHeader/";
import Select from "@layout/Select";
import { isEmpty, preloadImage } from "@helpers";
import { defaultPrintingSettings } from "@helpers";
import CountUp from "react-countup";
import PropTypes from "prop-types";
import Checkbox from "@layout/Checkbox";
import Radio from "@layout/Radio";
import { insert, remove } from "@helpers";
import UserEvents from "./partials/UserEvents";
import Modal from "@layout/Modal";
import SinglePayment from "./partials/Payments/SinglePayment";
import moment from "moment";
import ApiHelper from "@helpers/api-helper";
import Switch from "@layout/Switch";
import TableActions from "@layout/TableActions";
import { checkIfConditionIsFulfilled } from "../../helpers/users-helper";

export default class Users extends React.Component {
  constructor(props) {
    super(props);

    this.onRender = this.onRender.bind(this);
    this.handleBody = this.handleBody.bind(this);
    this.getCertType = this.getCertType.bind(this);
    this.onRenderDropdown = this.onRenderDropdown.bind(this);
    this.printCertificate = this.printCertificate.bind(this);
    this.fetchMinutes = this.fetchMinutes.bind(this);
    this.onRenderTh = this.onRenderTh.bind(this);
    this.onPrintFinished = this.onPrintFinished.bind(this);
    this.selectRow = this.selectRow.bind(this);
    this.onPrintCert = this.onPrintCert.bind(this);
    this.onExport = this.onExport.bind(this);
    this.checkEditable = this.checkEditable.bind(this);
    this.quickEdit = this.quickEdit.bind(this);
    this.toggleUser = this.toggleUser.bind(this);
    this.setupQuickEdit = this.setupQuickEdit.bind(this);
    this.onRenderColSpan = this.onRenderColSpan.bind(this);
    this.quickUpdateUser = this.quickUpdateUser.bind(this);
    this.onPrintCard = this.onPrintCard.bind(this);
    this.printCard = this.printCard.bind(this);
    this.createUser = this.createUser.bind(this);
    this.printFunction = this.printFunction.bind(this);
    this.excludedArea = null;
    this.overridePoints = null;

    this.state = {
      toggledCells: [],
      errors: [],
      updating: false,
      wordConfirmed: false,
      cardSettings: JSON.parse(this.props.event.extra.cardPrintingSettings),
      certification: {
        settings: JSON.parse(
          this.props.event.extra.certificationPrintingSettings
        ),
        printing: false,
        user: null,
        userSettings: null,
      },
      selectedAll: false,
      selectedUsers: [],
      quickEdit: null,
      quickEditTrIndex: null,
      quickEditFields: {},
      users: props.users,
      nativePrintActive: false,
      badgeHtml: null,
      hasPolicy: true,
      resetHeader: false,
      fetching: false,
    };

    if (isEmpty(this.state.certification.settings)) {
      this.state.certification.settings = defaultPrintingSettings;
    }

    window.addEventListener("afterprint", () => {
      this.onPrintFinished();
    });
  }

  onPrintFinished() {
    this.setState(
      Object.assign({}, this.state, {
        certification: Object.assign({}, this.state.certification, {
          printing: false,
          user: null,
          userSettings: null,
        }),
        nativePrintActive: false,
        badgeHtml: null,
      }),
      () => this.props.disableNativePrinting()
    );
  }

  printFunction() {
    window.scriptCaller();
  }

  fetchMinutes() {
    this.props.fetchMinutes(
      this.props.eventId,
      this.props.policyId,
      this.props.meta,
      false
    );
  }

  toggleActions(e, id) {
    this.excludedArea = e.target.parentNode;

    const toggledCells = [id];

    this.setState({ toggledCells });
  }

  handleBody(e) {
    if (
      this.state.toggledCells.length > 0 &&
      !this.excludedArea.contains(e.target)
    ) {
      this.setState({ toggledCells: [] });
    }
  }
  createObserver() {
    if (!window.IntersectionObserver) return;
    this.observer?.disconnect();
    const table = document.querySelector(".table-container.table-participants");
    const elementID = table.querySelector(".kmb-id");
    if (!table || !elementID) {
      return;
    }
    this.observer = new IntersectionObserver(
      ([e]) => {
        return table.classList.toggle("is-pinned", e.intersectionRatio < 0.1);
      },
      {
        threshold: [0.1],
        root: document.querySelector(".tab-content"),
        rootMargin: "40000px 0px 40000px 0px", //this big number ensures handling for vertical scroll
      }
    );
    this.observer.observe(elementID);
  }
  componentDidMount() {
    document.body.addEventListener("click", this.handleBody);
    // apply shadow on name
    this.createObserver();
    //  until here
    if (this.props.event.cardPrintingBanners.length > 0) {
      preloadImage(this.props.event.cardPrintingBanners[0].url);
    }
    if (!this.props.policyId) {
      return this.setState({ hasPolicy: false, users: [] });
    } else {
      this.props.loadRooms(this.props.eventId);
      if (this.props.policy.eventId == this.props.eventId) {
        this.props.getParticipants({
          eventId: this.props.eventId,
          policyId: this.props.policyId,
          query: {
            groupId: this.props.groupId || "",
            search: "",
          },
        });
        this.props.getGroups(this.props.eventId, this.props.policyId);
        this.props.loadPolicy(this.props.eventId, this.props.policyId);
      }
    }

    this.props.setupPrinters();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = {};
    if (
      this.props.policyId !== nextProps.policyId ||
      (this.props.contentLoaded == false && nextProps.contentLoaded == true)
    ) {
      newState.resetHeader = true;
      newState.users = [];
      if (!nextProps.policyId) {
        newState.hasPolicy = false;
      } else {
        newState.hasPolicy = true;

        this.props.getParticipants({
          eventId: nextProps.eventId,
          policyId: nextProps.policyId,
          query: {
            groupId: nextProps.groupId || "",
            search: nextProps.policyId !== this.props.policyId ? "" : undefined,
          },
        });
        this.props.getGroups(nextProps.eventId, nextProps.policyId);
      }
    } else {
      newState.users = nextProps.users;
    }
    newState.quickEdit = null;
    newState.quickEditTrIndex = null;
    newState.quickEditFields = {};
    newState.errors = [];
    newState.selectedAll = false;
    newState.selectedUsers = [];
    newState.fetching = nextProps.fetching;
    this.setState(newState, () => {
      this.setState({ resetHeader: false });
    });
  }

  componentWillUnmount() {
    this.observer?.disconnect();
    document.body.removeEventListener("click", this.handleBody);
    window.removeEventListener("afterprint", this.onPrintFinished);
  }

  getCertType(event) {
    if (event.timeManagement !== 1) {
      return "simple";
    } else {
      const timeSettings = event.settings.time;
      if (timeSettings.proportional === 1) {
        if (!timeSettings.minutes || !timeSettings.points) {
          return "simple";
        }
        if (!timeSettings.minMinutes || !timeSettings.minPoints) {
          return "points";
        }
        return "proportionalPoints";
      } else {
        if (!timeSettings.minutes || !timeSettings.points) {
          return "simple;";
        }
        return "points";
      }
    }
  }

  onRenderDropdown({ key, obj }) {
    if (key === "overridePoints") {
      const maxPoints = this.props.event.settings.time.points
        ? this.props.event.settings.time.points
        : null;
      const min = 1;
      return (
        <span>
          {obj.label}
          <input
            type="number"
            ref={(ref) => (ref ? (this.overrideInput = ref) : "")}
            min={min}
            max={maxPoints}
            placeholder="Add points"
            defaultValue={obj.userPoints > 0 ? obj.userPoints : 1}
            onChange={(e) => {
              let val = parseInt(e.target.value);
              if (val < min || isNaN(val)) {
                val = min;
              }
              if (val > maxPoints) {
                val = maxPoints;
              }
              this.overrideInput.value = val;
            }}
          />
        </span>
      );
    }
  }

  selectRow(id) {
    let selectedUsers = [...this.state.selectedUsers];

    if (this.state.selectedAll) {
      selectedUsers = [...this.state.users]
        .map((u) => u.id)
        .filter((i) => i !== id);
    } else {
      if (selectedUsers.includes(id)) {
        selectedUsers = selectedUsers.filter((u) => u !== id);
      } else {
        selectedUsers = [...selectedUsers, ...[id]];
      }
    }

    this.setState({ selectedUsers, selectedAll: false });
  }
  convertBooleansToYesNo(rootObjectUser) {
    Object.entries(rootObjectUser).map(([k, v]) => {
      if (!this.props.policy.extraSettings.hasOwnProperty(k)) return;
      if (this.props.policy.extraSettings[k].type !== "yes_no") return;
      if (v === "") return (rootObjectUser[k] = "");
      if (v === null) return (rootObjectUser[k] = "");

      const isYes = v == "1" || v === "true";
      rootObjectUser[k] = isYes ? "Yes" : "No";
    });
    return rootObjectUser;
  }
  onPrintCert() {
    const { selectedUsers } = this.state;
    let iterable = [...this.state.users];
    if (selectedUsers.length > 0) {
      iterable = iterable.filter((u) => selectedUsers.includes(u.id));
    }
    const userSettings = [];
    const users = iterable.map((user) => {
      const rootObjectUser = this.convertBooleansToYesNo(rootLevelObject(user));

      userSettings.push({
        action: user.points && user.points > 0 ? "withPoints" : "normal",
        points: user.points && user.points > 0 ? user.points : false,
      });
      return rootObjectUser;
    });
    this.printCertificate(userSettings, users);
  }

  printCard(user) {
    const { printerType } = this.props.printers;
    if (printerType === "generic") {
      // generic native print
      if (Array.isArray(user)) {
        const users = user.map((u) => u.id);
        this.props
          .getUserBadges(this.props.eventId, this.props.policyId, users)
          .then((output) => {
            this.props.enableNativePrinting(
              "badge",
              output.html,
              this.printFunction
            );
            this.setState({ nativePrintActive: true, badgeHtml: output.html });
          });
      } else {
        this.props
          .getUserBadge(this.props.eventId, this.props.policyId, user.id)
          .then((output) => {
            this.props.enableNativePrinting(
              "badge",
              output.data.html,
              this.printFunction
            );
            this.setState({ nativePrintActive: true, badgeHtml: output.html });
          });
      }
    } else {
      // go dymo style
      this.props.printCard(user);
    }
  }

  nativePrintCallback(output) {
    this.props.enableNativePrinting("cert", output.html, this.printFunction);
    this.setState({ nativePrintActive: true });
  }

  printCertificate(action, user) {
    const userSettings = {
      action,
      points: 0,
    };
    const overridePoints = action === "overridePoints";

    if (action !== "normal") {
      if (overridePoints) {
        userSettings.points = this.overrideInput.value;
      } else {
        userSettings.points = user.points;
      }
    }

    if (Array.isArray(user)) {
      this.props
        .getUsersCertificates(
          this.props.eventId,
          this.props.policyId,
          user.map((u) => u.id),
          overridePoints,
          userSettings.points,
          action === "normal"
        )
        .then((output) => {
          this.nativePrintCallback(output);
        });
    } else {
      this.props
        .getUserCertificate(
          this.props.eventId,
          this.props.policyId,
          user.id,
          overridePoints,
          userSettings.points,
          action === "normal"
        )
        .then((output) => {
          this.nativePrintCallback(output);
        });
    }
  }

  onPrintCard() {
    let { selectedUsers } = this.state;
    if (this.state.selectedAll) {
      selectedUsers = this.props.users.map((u) => u.id);
    }

    let iterable = [...this.state.users];
    const { printerType } = this.props.printers;
    if (selectedUsers.length > 0) {
      iterable = iterable.filter((u) => selectedUsers.includes(u.id));
    }
    const users = iterable.map((user) => {
      const rootObjectUser = this.convertBooleansToYesNo(rootLevelObject(user));
      return rootObjectUser;
    });
    if (printerType === "generic") {
      this.printCard(users);
    } else {
      users.forEach((u) => this.printCard(u));
    }
  }

  onRenderTh(col) {
    if (col.key === "bulkSelect") {
      return (
        <Checkbox
          style={{ width: 19 }}
          id="user-all"
          checked={this.state.selectedAll}
          onChange={() => {
            this.setState((prevState) => ({
              selectedAll: !prevState.selectedAll,
              selectedUsers: [],
            }));
          }}
        />
      );
    }
  }

  quickEdit(e) {
    const { name, value } = e.target;
    this.setState({
      quickEditFields: Object.assign({}, this.state.quickEditFields, {
        [name]: value,
      }),
    });
  }

  createUser(
    value,
    policyId,
    eventId,
    userId,
    saveAndPrint = false,
    selectedUserid
  ) {
    this.props
      .createUser(
        value,
        policyId,
        eventId,
        userId,
        saveAndPrint,
        undefined,
        selectedUserid
      )
      .then(({ printerType, user }) => {
        if (printerType === "generic" && saveAndPrint) {
          this.props.enableNativePrinting();
          this.setState(
            {
              nativePrintActive: user,
            },
            () => {
              this.onPrintFinished();
            }
          );
        }
      });
  }

  checkEditable(key, id) {
    const { settings, extraSettings } = this.props.policy;
    const { columns } = this.props;
    const { quickEdit, quickEditFields } = this.state;

    if (quickEdit !== id) {
      return false;
    }

    if (
      settings.hasOwnProperty(key) &&
      columns[this.props.eventId][this.props.policy.id][key]?.editable
    ) {
      // is a setting
      // and is editable

      const hasError = this.state.errors.includes(key);

      return (
        <div className="form-container">
          <div className={`form-group${hasError ? " has-error" : ""}`}>
            <input
              type="text"
              className="form-control"
              name={key}
              value={quickEditFields[key]}
              onChange={this.quickEdit}
            />
          </div>
        </div>
      );
    }
    if (
      extraSettings.hasOwnProperty(key) &&
      columns[this.props.eventId][this.props.policy.id][key]?.editable
    ) {
      return this.renderExtraField(key, extraSettings[key]);
    }
  }

  renderExtraField(key, settings) {
    const { quickEditFields } = this.state;
    const hasError = this.state.errors.includes(key);
    switch (settings.type) {
      case "textarea": {
        return (
          <div className="form-container">
            <div className={`form-group${hasError ? " has-error" : ""}`}>
              <textarea
                style={{ minWidth: 150 }}
                className="form-control"
                name={key}
                value={quickEditFields[key]}
                onChange={this.quickEdit}
              />
            </div>
          </div>
        );
      }
      case "text": {
        return (
          <div className="form-container">
            <div className={`form-group${hasError ? " has-error" : ""}`}>
              <input
                type="text"
                className="form-control"
                name={key}
                value={quickEditFields[key]}
                onChange={this.quickEdit}
              />
            </div>
          </div>
        );
      }

      case "dropdown": {
        const { quickEditFields } = this.state;
        const value = quickEditFields[key];
        let options = settings.value;
        const buildOptions = {};
        if (typeof options === "string") {
          options = JSON.parse(options);
        }
        options.forEach((o) => (buildOptions[o] = o));
        return (
          <div className="form-container">
            <div className={`form-group${hasError ? " has-error" : ""}`}>
              <Select
                options={buildOptions}
                value={value}
                className="custom-field-dropdown"
                onChange={(value) =>
                  this.quickEdit({ target: { name: key, value } })
                }
              />
            </div>
          </div>
        );
      }

      case "yes_no": {
        let value = quickEditFields[key];
        if (value === true) {
          value = "yes";
        } else if (value === false) {
          value = "no";
        }
        return (
          <div className="form-container field-type type-radio kmb-radio">
            <Radio
              id={key}
              name={key}
              checked={value ? value.toLowerCase() : undefined}
              onChange={(value) => {
                return this.quickEdit({ target: { name: key, value } });
              }}
            />
          </div>
        );
      }
    }
  }

  setupQuickEdit(user) {
    const { policy, eventId } = this.props;
    const fields = {};
    const columns =
      this.props.columns[eventId] && this.props.columns[eventId][policy.id]
        ? this.props.columns[eventId][policy.id]
        : this.props.columns;

    Object.entries(columns).forEach(([k, v]) => {
      if (v.editable && user[k]) {
        fields[k] =
          v.hasOwnProperty("type") && v.type === "yes_no"
            ? user[k].toLowerCase()
            : user[k];
      }
    });
    return fields;
  }

  onRender(col, item, trIndex) {
    const isEditable = this.checkEditable(col.key, item.id);
    const hasError = this.state.errors.includes(col.key);

    if (
      isEditable &&
      checkIfConditionIsFulfilled(
        this.props.policy.extraSettings[col.key],
        item
      )
    ) {
      return isEditable;
    }
    switch (col.key) {
      case "_name": {
        return (
          <div style={{ display: "flex", gap: 12 }}>
            <div
              title={`${item.active ? "Active" : "Inactive"}/${
                item.registered ? "Verified" : "UnVerified"
              }`}
              style={{ width: 35, height: 35, position: "relative" }}
            >
              {!isEmpty(item.crmUserProfilePhoto) ? (
                <img
                  src={item.crmUserProfilePhoto[0].url}
                  style={{
                    width: 35,
                    height: 35,
                    borderRadius: 35,
                    objectFit: "cover",
                    border: !item.active
                      ? "4px solid #FEDF89"
                      : "4px solid transparent",
                  }}
                />
              ) : (
                <span
                  style={{
                    width: 35,
                    height: 35,
                    borderRadius: 35,
                    objectFit: "cover",
                    border: !item.active
                      ? "4px solid #FEDF89"
                      : "4px solid transparent",
                    color: "white",
                    background: "#d5d9df",
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="icon-username"
                ></span>
              )}
              <span
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  border: "2px solid white",
                  background: item.registered ? "#12B76A" : "#D0D5DD",
                  borderRadius: "100%",
                  width: 10,
                  height: 10,
                }}
              ></span>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontWeight: "bold", paddingBottom: 5 }}>{`${
                item.lastName || ""
              } ${item.firstName || ""}`}</div>
              <div
                style={{ color: "#928F98", fontSize: 12 }}
              >{`Created at: ${moment(item.tz, "YYYY-MM-DD HH:mm").format(
                "DD MMMM YYYY HH:mm"
              )}`}</div>
            </div>
          </div>
        );
      }
      case "active": {
        return (
          <Switch
            id={`staff-active-${item.id}`}
            isActive={item["active"]}
            onChange={(value) =>
              this.props.updateUser(
                { active: value },
                this.props.policyId,
                this.props.eventId,
                item.id,
                undefined,
                undefined,
                true
              )
            }
          />
        );
      }
      case "eventUserActive": {
        return (
          <Switch
            id={`staff-active-${item.id}`}
            isActive={
              item["eventUserActive"] === undefined
                ? item["active"]
                : item["eventUserActive"]
            }
            onChange={(value) =>
              this.props.updateUser(
                { active: value },
                this.props.policyId,
                this.props.eventId,
                item.id,
                undefined,
                undefined,
                true
              )
            }
          />
        );
      }
      case "bulkSelect": {
        return (
          <Checkbox
            id={`user-${item.id}`}
            checked={
              this.state.selectedAll ||
              this.state.selectedUsers.includes(item.id)
            }
            onChange={() => this.selectRow(item.id)}
          />
        );
      }
      case "createdAt": {
        return moment(item.tz).format(ApiHelper.dateFormat);
      }
      case "speaker": {
        let value = "No";
        if (item.speaker === "1") {
          value = "Yes";
        }
        return value;
      }
      case "registered": {
        let value = "No";
        if (item.registered == 1) {
          value = "Yes";
        }
        return value;
      }
      case "eventUserRegistered": {
        let value = "No";
        if (item.eventUserRegistered == 1) {
          value = "Yes";
        }
        return value;
      }
      case "quickEdit": {
        const { quickEdit } = this.state;
        const removeOnly = quickEdit === item.id;
        return (
          <i
            className={removeOnly ? "fa fa-undo" : "icon-edit"}
            aria-hidden="true"
            title="Quick Edit"
            onClick={() => this.toggleUser(item, trIndex, removeOnly)}
          ></i>
        );
      }
      case "text": {
        return (
          <div className="form-container">
            <div className={`form-group${hasError ? " has-error" : ""}`}>
              <input
                type="text"
                className="form-control"
                name={col.key}
                value={this.state.quickEditFields[col.key]}
                onChange={this.quickEdit}
              />
            </div>
          </div>
        );
      }

      case "events": {
        return (
          <button
            className="btn transparent table-button-link"
            onClick={() => this.props.showUserEvents(item.id)}
          >
            <span className="icon-events"></span>Events
          </button>
        );
      }

      case "card": {
        return (
          <button
            className="btn transparent table-button-link"
            onClick={() => this.printCard(item)}
          >
            <span className="icon-card-user"></span>Card
          </button>
        );
      }
      case "subscription": {
        let noFees = false;
        let color = "";
        let className = "icon-notpaid";
        let info = "Not Paid";
        let title = "";
        const colorGreen = "#14da9e";
        const colorBlue = "#3C96FF";
        const colorRed = "var(--error-color)";
        const hasPayment =
          item.subscriptionSumDiscount || item.subscriptionSumPaid;

        const hasSponsorPayment = item.subscriptionSponsorAmount;
        if (item.subscriptionId) {
          if (
            item.subscriptionPrice === 0 &&
            item.subscriptionDueAmount === 0
          ) {
            // free
            color = item.subscriptionSponsoredBy ? colorBlue : colorGreen;
            info = "Free";
            title = "Free (no subscription fees)";
            className = "icon-paid";
          } else if (item.subscriptionDueAmount > 0) {
            // has due amount
            if (hasSponsorPayment) {
              // partially sponsored
              color = colorBlue;
              info = "Partial";
              className = "icon-partial";
              title = "Partially paid by Sponsor";
            } else if (hasPayment) {
              color = colorGreen;
              info = "Partial";
              className = "icon-partial";
              title = "Partially paid by the Participant";
            } else {
              color = item.subscriptionSponsoredBy ? colorBlue : colorRed;
              info = "Not Paid";
              className = "icon-notpaid";
              title = "Not Paid";
            }
          } else if (item.subscriptionDueAmount < 0) {
            // needs refund
            className = "icon-refund";
            if (hasSponsorPayment) {
              color = colorBlue;
              info = "Refund";
              title = "Refund the Sponsor";
            } else {
              color = colorGreen;
              info = "Refund";
              title = "Refund the Participant";
            }
          } else {
            // paid
            if (hasSponsorPayment) {
              /* paid by sponsor*/
              className = "icon-paid";
              color = colorBlue;
              info = "Paid";
              title = "Paid by the Sponsor";
            } else {
              /* paid by participant*/
              className = "icon-paid";
              color = colorGreen;
              info = "Paid";
              title = "Paid by the Participant";
            }
          }
        } else {
          noFees = true;
        }
        return noFees ? (
          <span>No subscription fees</span>
        ) : (
          <button
            title={title}
            style={{ minWidth: 129 }}
            className="btn transparent table-button-link"
            onClick={() => {
              this.props.showModal(
                <SinglePayment
                  userId={item.id}
                  policyId={this.props.policyId}
                  registration={true}
                  loadUser={this.props.loadUser}
                />
              );
            }}
          >
            <span className={className} style={{ color, fontSize: 13 }}></span>
            {info}
          </button>
        );
      }

      case "minutes": {
        if (item.minutes >= 0) {
          return <CountUp start={0} end={item.minutes} duration={0.5} />;
        }

        return "";
      }

      case "certificate": {
        const button = (
          <button className="btn transparent table-button-link">
            <span className="icon-print-certificate"></span>Certificate
          </button>
        );
        const certType = this.getCertType(this.props.event);

        switch (certType) {
          case "simple": {
            return (
              <button
                className="btn transparent table-button-link"
                onClick={() => this.printCertificate("normal", item)}
              >
                <span className="icon-print-certificate"></span>Certificate
              </button>
            );
          }
          case "points":
          case "proportionalPoints": {
            const points = item.hasOwnProperty("points") ? item.points : 0;
            const options = {
              withPoints: `Certificate with points (${points})`,
              normal: "Simple Certificate",
              overridePoints: {
                label: "Override Points",
                userPoints: points,
              },
            };

            if (points === 0) {
              delete options["withPoints"];
            }

            return (
              <Select
                placeholder={button}
                className="certificate"
                isDropDownButton={true}
                defaultAlways={true}
                hasIndicator={false}
                placeholderInsideSelect={false}
                options={options}
                onRender={this.onRenderDropdown}
                onChange={(action) => {
                  this.printCertificate(action, item);
                }}
                onToggle={this.onToggle}
              />
            );
          }
        }

        break;
      }

      case "points": {
        if (item.sumMinutes >= 0 && col.value !== "" && col.value !== null) {
          return <CountUp start={0} end={col.value} duration={0.5} />;
        }
        return "";
      }

      case "actions": {
        const isCrm = this.props.policy.saveUsersToCrm;
        return (
          <TableActions
            entity="participant"
            item={item}
            delete={isCrm ? false : true}
            onEdit={() =>
              this.props.editUser(this.props.policy, item, this.createUser)
            }
            onDelete={() =>
              this.props.deleteItem(
                this.props.eventId,
                this.props.policyId,
                item.id
              )
            }
            custom={(toggled) =>
              isCrm ? this.customActions(item, toggled) : false
            }
            customPosition="right"
          />
        );
      }
    }
  }
  customActions(item) {
    const deleteBody = (
      <div>
        <p>
          This action will permanently delete the participant with ID:{" "}
          <strong>{item.id}</strong>
        </p>
        <p>
          <span>{item.name}</span>
        </p>
        <p>Are you sure that you want to delete this participant?</p>
        <p>If yes, then please type: CONFIRM DELETE</p>
        <Confirmer
          word="confirm delete"
          onSuccess={() => {
            this.setState({ wordConfirmed: true });
          }}
          onFail={() => {
            if (this.state.wordConfirmed) {
              this.setState({ wordConfirmed: false });
            }
          }}
        />
      </div>
    );

    const deleteTd = (
      <span className="confirm-holder">
        <Confirm
          onConfirm={() => {
            this.setState(
              {
                wordConfirmed: false,
              },
              () =>
                this.props.deleteItem(
                  this.props.eventId,
                  this.props.policyId,
                  item.id
                )
            );
          }}
          body={deleteBody}
          className="delete-event"
          confirmText="Confirm Delete"
          disabled={this.state.wordConfirmed ? false : true}
          title="Delete Action! Are you sure?"
          onClose={() => {
            this.setState({ wordConfirmed: false });
          }}
        >
          <Tooltip title="Unassign from event">
            <button className="btn circle">
              <span className="icon-all-out"></span>
            </button>
          </Tooltip>
        </Confirm>
      </span>
    );

    return deleteTd;
  }

  quickUpdateUser(data, policyId, eventId, userId) {
    const { extraSettings, settings } = this.props.policy;
    const errors = [];
    Object.entries(data).forEach(([k, v]) => {
      const field = settings[k] || extraSettings[k];
      if (field && field.required && v === "") {
        errors.push(k);
      }

      if (
        extraSettings.hasOwnProperty(k) &&
        extraSettings[k].type === "yes_no"
      ) {
        data[k] = v.toLowerCase() === "yes" ? true : false;
      }
    });

    if (errors.length > 0) {
      return this.setState({
        errors,
      });
    }

    this.props.updateUser(data, policyId, eventId, userId);
  }
  onRenderColSpan(item, trIndex) {
    return (
      <td key={`user-${trIndex}-colspan`} colSpan={item.colSpan}>
        <div className="text-right submit-container">
          <button
            type="button"
            className="btn cancel rounded"
            onClick={() => this.toggleUser(item.id, trIndex, true)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn save rounded"
            onClick={() => {
              return this.quickUpdateUser(
                this.state.quickEditFields,
                this.props.policyId,
                this.props.eventId,
                this.state.quickEdit
              );
            }}
          >
            Save
          </button>
        </div>
      </td>
    );
  }

  onExport(all = false) {
    const { exportUsers, policyId, eventId, columns } = this.props;
    const { selectedAll, selectedUsers } = this.state;
    const cols = [];
    const tempColumns =
      columns[eventId] && columns[eventId][policyId]
        ? columns[eventId][policyId]
        : columns;
    Object.entries(tempColumns).map(([k, v]) => {
      if (v.hasOwnProperty("checked") && v.checked) {
        cols.push(k);
      }
    });
    const users = selectedAll
      ? [...this.state.users].map((u) => u.id)
      : selectedUsers;

    exportUsers(eventId, policyId, cols, users, all, this.props.groupId);
  }

  toggleUser(user, trIndex, removeOnly = false) {
    const { eventId, policy } = this.props;
    let { users } = this.state;
    const { quickEdit, quickEditTrIndex } = this.state;
    const tempColumns =
      this.props.columns[eventId] && this.props.columns[eventId][policy.id]
        ? this.props.columns[eventId][policy.id]
        : this.props.columns;
    users = [...users];
    if (quickEdit) {
      trIndex = trIndex > quickEditTrIndex ? trIndex - 1 : trIndex;
      users = remove(users, quickEditTrIndex);
      if (removeOnly) {
        return this.setState({
          users,
          quickEdit: null,
          quickEditTrIndex: null,
          errors: [],
        });
      }
    }
    users = insert(users, trIndex + 1, {
      colSpan: Object.keys(tempColumns).length,
      user,
    });
    this.setState({
      quickEdit: user.id,
      quickEditTrIndex: trIndex + 1,
      quickEditFields: this.setupQuickEdit(user),
      users,
      errors: [],
    });
  }

  normalizeUsers() {
    let activeUser = {};
    let header = null;
    const users = [...this.state.users].map((user) => {
      const rootObjectUser = this.convertBooleansToYesNo(rootLevelObject(user));

      if (
        this.props.userEvents.active &&
        user.id === this.props.userEvents.userId
      ) {
        activeUser = rootObjectUser;
        header = (
          <span>
            {rootObjectUser.firstName + " " + rootObjectUser.lastName}
            <span className="minutes">
              Total Minutes:{" "}
              <span>
                {rootObjectUser.minutes ? parseInt(rootObjectUser.minutes) : 0}
              </span>
            </span>
          </span>
        );
      }
      if (!isEmpty(user.crmUserProfilePhoto)) {
        rootObjectUser["crmUserProfilePhoto"] = user.crmUserProfilePhoto;
      }
      return rootObjectUser;
    });
    return { activeUser, header, users };
  }

  normalizeColumns() {
    const { eventId, policy } = this.props;
    let columns = {};
    const fixed = {};
    const tempColumns =
      this.props.columns[eventId] && this.props.columns[eventId][policy.id]
        ? this.props.columns[eventId][policy.id]
        : this.props.columns.default;

    Object.entries(tempColumns).map(([k, v]) => {
      if (k === "firstName") return;
      if (k === "createdAt") return;
      if (k === "lastName") {
        columns["_name"] = {
          label: "Name",
          name: "Name",
          sortable: true,
          sortValue: "lastName",
        };
        if (k === this.props.activeSort) {
          columns["_name"].activeSort = true;
        }
        return;
      }
      if (v.hasOwnProperty("filter")) {
        if (v.filter === true) {
          const clone = { ...v };
          if (k === this.props.activeSort) {
            clone.activeSort = true;
          } else if (clone.hasOwnProperty("activeSort")) {
            delete clone.activeSort;
          }
          if (
            k === "actions" ||
            k === "events" ||
            k === "certificate" ||
            k === "card" ||
            k === "subscription"
          ) {
            fixed[k] = clone;
          } else {
            columns[k] = clone;
          }
        }
      } else {
        if (
          k === "actions" ||
          k === "events" ||
          k === "certificate" ||
          k === "card" ||
          k === "subscription"
        ) {
          // keep it at
          // the end.

          fixed[k] = v;
        } else {
          columns[k] = v;
        }
      }
    });
    if (!!columns.active && (this.props.policy || {}).type === "crm") {
      columns.eventUserActive = { ...columns.active };
      delete columns.active;
    }
    if (!!columns.registered && (this.props.policy || {}).type === "crm") {
      columns.eventUserRegistered = { ...columns.registered };
      delete columns.registered;
    }
    columns = { ...columns, ...fixed };
    delete columns.resume;
    delete columns.crmUserProfilePhoto;
    delete columns.speaker;
    delete columns.password;

    return { columns };
  }

  render() {
    const { userEvents } = this.props;
    const { users, header, activeUser } = this.normalizeUsers();

    const { columns } = this.normalizeColumns();
    const { selectedUsers } = this.state;

    if (!this.state.hasPolicy) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "40vh",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <span className="coplimentary">{"Loading Policy Schema"}</span>
        </div>
      );
    }
    return (
      <React.Fragment>
        <UsersHeader
          {...this.props}
          reset={this.state.resetHeader}
          advancedSearchOptions={this.props.advancedSearchOptions}
          policy={this.props.policy}
          eventId={this.props.eventId}
          policyId={this.props.policyId}
          selectedUsers={selectedUsers}
          selectedAll={this.state.selectedAll}
          onPrintCert={this.onPrintCert}
          onPrintCard={this.onPrintCard}
          onExportUsers={this.onExport}
          createUser={this.createUser}
          onExportUsersAll={() => this.onExport(true)}
        />
        <div className="tab-content">
          <Table
            filtered={
              this.props.meta.search ||
              this.props.meta.groupId ||
              this.props.meta.participationType ||
              this.props.meta.hasHit ||
              this.props.meta.hasHit ||
              this.props.meta.minutesRange ||
              this.props.meta.dateRange
            }
            id={"policy-users"}
            columns={columns}
            items={users}
            updating={this.props.fetching}
            onRender={this.onRender}
            onRenderTh={this.onRenderTh}
            onSort={(key) =>
              this.props.sortUsers(this.props.eventId, this.props.policyId, key)
            }
            loadingRowsHeight={66}
            containerClassName={`table-container table-participants`}
            onRenderColSpan={this.onRenderColSpan}
          />

          <Modal
            title={header}
            show={userEvents.active}
            onClose={() => this.props.hideUserEvents()}
            className={"user-events-modal"}
          >
            <UserEvents
              user={activeUser}
              eventId={this.props.eventId}
              policyId={this.props.policyId}
            />
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

Users.propTypes = {
  event: PropTypes.object,
  isParticipantPolicy: PropTypes.bool,
  contentLoaded: PropTypes.bool,
  loadUsers: PropTypes.func.isRequired,
  loadPolicy: PropTypes.func.isRequired,
  getParticipants: PropTypes.func,
  loadUser: PropTypes.func,
  eventId: PropTypes.number.isRequired,
  policyId: PropTypes.number,
  setupPrinters: PropTypes.func.isRequired,
  fetchMinutes: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  advancedSearchOptions: PropTypes.object.isRequired,
  printers: PropTypes.object,
  addNotification: PropTypes.func.isRequired,
  sortUsers: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  loadEvent: PropTypes.func.isRequired,
  policy: PropTypes.object,
  users: PropTypes.array,
  columns: PropTypes.object,
  activeSort: PropTypes.string,
  fetching: PropTypes.bool,
  addUser: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  printCard: PropTypes.func.isRequired,
  getUserBadge: PropTypes.func.isRequired,
  getUserBadges: PropTypes.func.isRequired,
  getUserCertificate: PropTypes.func.isRequired,
  getUsersCertificates: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  timestamp: PropTypes.number,
  exportUsers: PropTypes.func.isRequired,
  userSet: PropTypes.bool.isRequired,
  showUserEvents: PropTypes.func.isRequired,
  userEvents: PropTypes.object.isRequired,
  hideUserEvents: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  showModal: PropTypes.func,
  groupId: PropTypes.string,
  changeTab: PropTypes.func,
  getGroups: PropTypes.func.isRequired,
  loadRooms: PropTypes.func.isRequired,
  disableNativePrinting: PropTypes.func.isRequired,
  enableNativePrinting: PropTypes.func.isRequired,
};
